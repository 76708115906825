import "@/assets/styles/main.scss";

import { library as fontAwesomeLibrary } from "@fortawesome/fontawesome-svg-core";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleLeft,
  faAngleRight,
  faBuilding,
  faCaretDown,
  faChartPie,
  faCheck,
  faCircle,
  faExclamationCircle,
  faExternalLinkSquareAlt,
  faLink,
  faMapMarkerAlt,
  faPencilAlt,
  faPlus,
  faTimesCircle,
  faUser,
  faUserCircle,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import Buefy from "buefy";
import Component from "vue-class-component";
import VueCookies from "vue-cookies";
import VueI18n from "vue-i18n";
import VueMeta from "vue-meta";
import VueMq from "vue-mq";
import VueProgressBar from "vue-progressbar";
import VueRouter from "vue-router";

import { configureInterceptors } from "@/api/instance";
import variables from "@/assets/styles/exports.scss";

export default function configureVue(vue) {
  vue.use(VueMeta, { refreshOnceOnNavigation: true });
  vue.use(VueCookies);
  vue.use(VueI18n);
  vue.use(VueRouter);
  vue.use(VueProgressBar);
  vue.use(Buefy, {
    defaultIconPack: "fas",
    defaultIconComponent: "font-awesome-icon",
  });
  vue.use(VueMq, {
    breakpoints: {
      tablet: parseInt(variables.tablet.slice(0, -2)),
      desktop: parseInt(variables.desktop.slice(0, -2)),
      widescreen: parseInt(variables.widescreen.slice(0, -2)),
      fullhd: parseInt(variables.fullhd.slice(0, -2)),
    },
  });

  vue.$cookies.config("1d");

  fontAwesomeLibrary.add(faAngleLeft);
  fontAwesomeLibrary.add(faAngleRight);
  fontAwesomeLibrary.add(faBuilding);
  fontAwesomeLibrary.add(faCaretDown);
  fontAwesomeLibrary.add(faChartPie);
  fontAwesomeLibrary.add(faCheck);
  fontAwesomeLibrary.add(faCircle);
  fontAwesomeLibrary.add(faClock);
  fontAwesomeLibrary.add(faExclamationCircle);
  fontAwesomeLibrary.add(faExternalLinkSquareAlt);
  fontAwesomeLibrary.add(faInstagram);
  fontAwesomeLibrary.add(faLink);
  fontAwesomeLibrary.add(faMapMarkerAlt);
  fontAwesomeLibrary.add(faPencilAlt);
  fontAwesomeLibrary.add(faPlus);
  fontAwesomeLibrary.add(faTimesCircle);
  fontAwesomeLibrary.add(faUser);
  fontAwesomeLibrary.add(faUserCircle);
  fontAwesomeLibrary.add(faWalking);

  vue.component("font-awesome-icon", FontAwesomeIcon);

  vue.config.productionTip = false;

  Sentry.init({
    vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_VERSION,
    integrations: [new Integrations.BrowserTracing()],
  });

  configureInterceptors(vue.prototype.$Progress);

  Component.registerHooks(["beforeRouteEnter", "beforeRouteUpdate"]);

  return vue;
}
