// This is the "store accessor":
// It initializes all the modules using a Vuex plugin (see store/index.ts)
// In here you import all your modules, call getModule on them to turn them
// into the actual stores, and then re-export them.

import { Store } from "vuex";
import { getModule } from "vuex-module-decorators";

import AuthModule from "@/store/modules/auth";
import ChoicesModule from "@/store/modules/choices";
import EventsModule from "@/store/modules/events";
import ModalsModule from "@/store/modules/modals";
import moduleRouter from "@/store/modules/router";
import UsersModule from "@/store/modules/users";

// Each store is the singleton instance of its module class
// Use these -- they have methods for state/getters/mutations/actions
// (result from getModule(...))
export let authStore: AuthModule;
export let choicesStore: ChoicesModule;
export let eventsStore: EventsModule;
export let modalsStore: ModalsModule;
export let usersStore: UsersModule;

// initializer plugin: sets up state/getters/mutations/actions for each store
export function initializeStores(store: Store<any>): void {
  authStore = getModule(AuthModule, store);
  choicesStore = getModule(ChoicesModule, store);
  eventsStore = getModule(EventsModule, store);
  modalsStore = getModule(ModalsModule, store);
  usersStore = getModule(UsersModule, store);
}

// for use in 'modules' store init (see store/index.ts), so each module
// appears as an element of the root store's state.
// (This is required!)
export const modules = {
  // TypeScript
  auth: AuthModule,
  choices: ChoicesModule,
  events: EventsModule,
  modals: ModalsModule,
  users: UsersModule,

  // JavaScript
  router: moduleRouter,
};
