import { Action, Module, Mutation } from "vuex-module-decorators";

import {
  apiPostAuthOtpComplete,
  apiPostAuthOtpStart,
  apiPostAuthSignOut,
} from "@/api/requests";
import { User } from "@/api/structs";
import { AuthStatus } from "@/constants";
import { Api } from "@/constants";
import ApiModule, { withStore } from "@/store/api";

interface PayloadStart {
  email: string;
}

interface PayloadComplete {
  email: string;
  digits: string;
}

@Module({ namespaced: true, name: "auth" })
export default class AuthModule extends ApiModule {
  public email: string = "";
  private _authStatus: AuthStatus = AuthStatus.Unknown;

  get isAuthenticated(): boolean {
    return this._authStatus === AuthStatus.Authenticated;
  }
  get isAnonymous(): boolean {
    return this._authStatus === AuthStatus.Anonymous;
  }
  get isUnknown(): boolean {
    return this._authStatus === AuthStatus.Unknown;
  }

  @Mutation
  SET_EMAIL(email: string) {
    this.email = email;
  }

  @Mutation
  SET_AUTH_STATUS(authStatus: AuthStatus) {
    this._authStatus = authStatus;
  }

  @Action
  async start({ email }: PayloadStart) {
    await withStore<null>(
      this.context.commit,
      Api.AuthStart,
      apiPostAuthOtpStart(email),
    );

    this.context.commit("SET_EMAIL", email);
  }

  @Action
  async complete({ email, digits }: PayloadComplete) {
    const user = await withStore<User>(
      this.context.commit,
      Api.AuthComplete,
      apiPostAuthOtpComplete(email, digits),
    );

    this.context.commit("users/SET_USER", user, { root: true });
    this.context.commit("SET_AUTH_STATUS", AuthStatus.Authenticated);
  }

  @Action
  async signOut() {
    await withStore<null>(this.context.commit, Api.AuthSignOut, apiPostAuthSignOut());
    this.context.commit("SET_AUTH_STATUS", AuthStatus.Anonymous);
    this.context.commit("users/SET_USER", null, { root: true });
  }
}
