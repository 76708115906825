import Vue from "vue";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

interface OpenModals {
  [key: string]: boolean;
}

@Module({ namespaced: true, name: "modals" })
export default class ModalsModule extends VuexModule {
  protected _openModals: OpenModals = {};

  get isOpen(): (name: string) => boolean {
    return name => {
      return this._openModals[name];
    };
  }

  @Mutation
  ADD_OPEN(name: string) {
    Vue.set<boolean>(this._openModals, name, true);
  }

  @Mutation
  REMOVE_OPEN(name: string) {
    Vue.delete(this._openModals, name);
  }

  @Action
  async open(name: string) {
    this.context.commit("ADD_OPEN", name);
  }

  @Action({ commit: "REMOVE_OPEN" })
  async close(name: string) {
    this.context.commit("REMOVE_OPEN", name);
  }
}
