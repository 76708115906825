
















import { Component, Vue } from "vue-property-decorator";

import ModalSignIn from "@/components/ModalSignIn.vue";
import { Api, Modal } from "@/constants";
import { authStore, modalsStore, usersStore } from "@/store";

@Component({ components: { ModalSignIn } })
export default class ModalSignInContainer extends Vue {
  get isOpen() {
    return modalsStore.isOpen(Modal.SignIn);
  }

  async close() {
    await modalsStore.close(Modal.SignIn);
  }

  get startProps() {
    return {
      isLoading: authStore.isPending(Api.AuthStart),
      errors: authStore.getErrors(Api.AuthStart),
    };
  }

  get completeProps() {
    return {
      isLoading: authStore.isPending(Api.AuthComplete),
      errors: authStore.getErrors(Api.AuthComplete),
      email: authStore.email,
    };
  }

  get profileEditProps() {
    return {
      isLoading: usersStore.isPending(Api.UsersUpdateUser),
      errors: usersStore.getErrors(Api.UsersUpdateUser),
      user: usersStore.user,
    };
  }

  async sendStartAuth($event: any) {
    await authStore.start({ email: $event.payload.email });

    if (authStore.isSuccess(Api.AuthStart)) {
      $event.proceed();
    }
  }

  async sendCompleteAuth($event: any) {
    await authStore.complete({
      email: $event.payload.email,
      digits: $event.payload.digits,
    });

    if (authStore.isSuccess(Api.AuthComplete)) {
      $event.proceed();
    }
  }

  async sendUpdateUser($event: any) {
    await usersStore.updateUser({
      fullName: $event.payload.fullName,
    });

    if (usersStore.isSuccess(Api.UsersUpdateUser)) {
      $event.proceed();
    }
  }
}
