import {
  DEL_QUERIES,
  DEL_QUERY,
  DEL_SCROLL_POSITION,
  DEL_SCROLL_POSITIONS,
  SET_QUERY,
  SET_SCROLL_POSITION,
} from "@/store/modules/router/mutations-types";

export const pushQuery = ({ commit }, payload) => {
  commit(SET_QUERY, payload);
};

export const popQuery = ({ commit, state }, payload) => {
  if (!(payload.id in state.queries)) {
    return null;
  }

  const query = { ...state.queries[payload.id].query };
  commit(DEL_QUERY, { id: payload.id });
  return query;
};

export const flushQueries = ({ commit }) => {
  commit(DEL_QUERIES);
};

export const pushScrollPosition = ({ commit }, payload) => {
  commit(SET_SCROLL_POSITION, payload);
};

export const popScrollPosition = ({ commit, state }, payload) => {
  if (!(payload.id in state.scrollPositions)) {
    return null;
  }

  const scrollPosition = { ...state.scrollPositions[payload.id].scrollPosition };
  commit(DEL_SCROLL_POSITION, { id: payload.id });
  return scrollPosition;
};

export const flushScrollPositions = ({ commit }) => {
  commit(DEL_SCROLL_POSITIONS);
};

export const flush = ({ dispatch }) => {
  dispatch("flushQueries");
  dispatch("flushScrollPositions");
};
