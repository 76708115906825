<template>
  <validation-provider mode="passive" :name="name" :rules="rules" v-slot="{ errors }">
    <b-field
      :label="label"
      :label-position="labelPosition"
      :type="{ 'is-danger': errors[0] }"
      :message="errors[0] ? errors : message"
    >
      <slot />
    </b-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "AppFormField",
  components: {
    ValidationProvider,
  },
  props: {
    name: String,
    rules: Object,
    label: String,
    labelPosition: String,
    message: {
      type: [Array, String],
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/utilities";

.field::v-deep {
  .help.is-light {
    color: $grey;
  }
}
</style>
