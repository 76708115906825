<template>
  <b-button type="is-white" native-type="submit" :loading="isLoading" :label="label" />
</template>

<script>
export default {
  name: "AppFormSubmit",
  props: {
    isLoading: Boolean,
    label: String,
  },
};
</script>
