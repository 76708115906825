<template>
  <b-step-item>
    <app-form :errors="errors" @submit="submit">
      <section class="modal-card-body">
        <app-form-field
          type="email"
          name="email"
          :rules="{ required: true, email: true }"
          :label="$t('authentication.form.enterEmailToSignInOrSignUp')"
          :message="emailMessage"
        >
          <b-input
            lazy
            v-model="email"
            :status-icon="false"
            type="email"
            placeholder="me@example.com"
            autocomplete="email"
          />
        </app-form-field>
        <app-form-errors />
      </section>
      <footer class="modal-card-foot is-justify-content-center">
        <app-form-submit
          :loading="isLoading"
          :label="$t('authentication.form.sendCode')"
        />
      </footer>
    </app-form>
  </b-step-item>
</template>

<script>
import { extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";

import AppForm from "@/components/AppForm";
import AppFormErrors from "@/components/AppFormErrors";
import AppFormField from "@/components/AppFormField";
import AppFormSubmit from "@/components/AppFormSubmit";

extend("email", email);
extend("required", required);

export default {
  name: "ModalSignInStepAStart",
  components: {
    AppFormSubmit,
    AppFormErrors,
    AppFormField,
    AppForm,
  },
  props: {
    isLoading: Boolean,
    errors: Object,
  },
  data: function () {
    return { email: "" };
  },
  methods: {
    submit() {
      this.$emit("submitted", {
        payload: { email: this.email },
      });
    },
  },
  computed: {
    emailMessage() {
      if (navigator.userAgent.toLowerCase().includes("instagram")) {
        return this.$t("authentication.form.recommendToUseNativeBrowser");
      }

      return "";
    },
  },
};
</script>
