import {
  flush,
  flushQueries,
  flushScrollPositions,
  popQuery,
  popScrollPosition,
  pushQuery,
  pushScrollPosition,
} from "@/store/modules/router/actions";
import {
  delQueries,
  delQuery,
  delScrollPosition,
  delScrollPositions,
  setQuery,
  setScrollPosition,
} from "@/store/modules/router/mutations";
import {
  DEL_QUERIES,
  DEL_QUERY,
  DEL_SCROLL_POSITION,
  DEL_SCROLL_POSITIONS,
  SET_QUERY,
  SET_SCROLL_POSITION,
} from "@/store/modules/router/mutations-types";

const moduleRouter = {
  namespaced: true,
  state: {
    queries: {},
    scrollPositions: {},
  },
  mutations: {
    [SET_QUERY]: setQuery,
    [DEL_QUERY]: delQuery,
    [DEL_QUERIES]: delQueries,
    [SET_SCROLL_POSITION]: setScrollPosition,
    [DEL_SCROLL_POSITION]: delScrollPosition,
    [DEL_SCROLL_POSITIONS]: delScrollPositions,
  },
  actions: {
    popQuery,
    pushQuery,
    flushQueries,
    popScrollPosition,
    pushScrollPosition,
    flushScrollPositions,
    flush,
  },
};

export default moduleRouter;
