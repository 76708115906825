import axios from "axios";

import ProgressTracker from "@/api/progress-tracker";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL.replace(
    "<sameHostname>",
    window.location.hostname,
  ),
  withCredentials: true,
});

export function configureInterceptors(progress) {
  const progressTracker = new ProgressTracker(progress);

  api.interceptors.request.use(
    config => progressTracker.requestHandler(config),
    error => progressTracker.errorHandler(error),
  );
  api.interceptors.response.use(
    config => progressTracker.responseHandler(config),
    error => progressTracker.errorHandler(error),
  );
}

export default api;
