<template>
  <div>
    <app-modal
      :animation="null"
      :active="isOpen"
      :can-cancel="false"
      :trap-focus="true"
      :full-screen="true"
    >
      <div class="modal-card" style="width: auto">
        <header
          class="modal-card-head is-justify-content-flex-end has-background-cocoa"
          style="border-bottom: none; border-radius: 0"
        >
          <div>
            <font-awesome-icon :icon="['fas', 'times-circle']" @click="closeModal" />
          </div>
        </header>
        <section class="modal-card-body">
          <aside class="menu">
            <ul class="menu-list">
              <li>
                <div
                  class="is-flex is-align-items-center"
                  style="
                     {
                      border-radius: 2px;
                      color: #dbdbdb;
                      padding: 0.5em 0.75em;
                    }
                  "
                >
                  <app-avatar
                    :avatar="avatar"
                    :alt="fullName || defaultFullName"
                    :size="48"
                  />
                  <div
                    class="ml-3"
                    style="
                       {
                        flex: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }
                    "
                  >
                    <strong>{{ fullName || defaultFullName }}</strong>
                  </div>
                </div>
              </li>
              <hr class="dropdown-divider" />

              <li v-for="item in items" :key="item.id">
                <hr class="dropdown-divider" v-if="item.type === 'divider'" />
                <a v-else @click="item.onClick">{{ item.name }}</a>
              </li>
            </ul>
          </aside>
        </section>
      </div>
    </app-modal>
    <div class="level-item" @click="openModal">
      <app-avatar :avatar="avatar" :alt="fullName || defaultFullName" :size="24" />
      <font-awesome-icon class="ml-3" :icon="['fas', 'caret-down']" />
    </div>
  </div>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import AppModal from "@/components/AppModal";

export default {
  name: "TheMenuModal",
  components: { AppAvatar, AppModal },
  props: {
    fullName: String,
    avatar: Object,
    items: Array,
    isOpen: Boolean,
    openModal: Function,
    closeModal: Function,
  },
  computed: {
    defaultFullName() {
      return this.$t("users.defaultFullName");
    },
  },
};
</script>
