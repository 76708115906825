import Router from "vue-router";

import store, { authStore, usersStore } from "@/store";
import NotFoundView from "@/views/NotFoundView.vue";

const router = new Router({
  mode: "history",
  base: "/",
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      store
        .dispatch("router/popScrollPosition", { id: to.name })
        .then(scrollPosition => {
          if (scrollPosition && to.query.getaway) {
            resolve(scrollPosition);
          }

          if (savedPosition) {
            resolve(savedPosition);
          }

          resolve({ x: 0, y: 0 });
        });
    });
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "event-list" */ "./views/EventListView.vue"),
      meta: {
        keepState: true,
      },
      props(route) {
        return {
          cityId: route.query.cityId || "",
          styleId: route.query.styleId || "",
        };
      },
    },
    {
      path: "/events/my",
      name: "events-my",
      component: () =>
        import(/* webpackChunkName: "events-my" */ "./views/MyEventListView.vue"),
      meta: {
        keepState: true,
        authOnly: true,
      },
    },
    {
      path: "/events/add",
      name: "event-add",
      component: () =>
        import(/* webpackChunkName: "event-add" */ "./views/EventAddView.vue"),
      meta: {
        authOnly: true,
      },
    },
    {
      path: "/events/:id",
      name: "event-detail",
      component: () =>
        import(/* webpackChunkName: "event-detail" */ "./views/EventDetailView.vue"),
    },
    {
      path: "/events/:id/edit",
      name: "event-edit",
      component: () =>
        import(/* webpackChunkName: "event-detail" */ "./views/EventEditView.vue"),
    },
    {
      path: "/motherlode",
      name: "experiments",
      component: () =>
        import(/* webpackChunkName: "experiments" */ "./views/ExperimentsView.vue"),
    },
    {
      path: "/profile",
      name: "profile-edit",
      component: () =>
        import(/* webpackChunkName: "profile-detail" */ "./views/ProfileEditView.vue"),
      meta: {
        authOnly: true,
      },
    },
    {
      path: "*",
      name: "not-found",
      component: NotFoundView,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (authStore.isUnknown) {
    await usersStore.loadUser();
  }

  if (to.meta.authOnly && !usersStore.user) {
    next({ name: "home" });
  }

  store.dispatch("router/popQuery", { id: to.name }).then(query => {
    if (to.meta.keepState && to.query.getaway && query) {
      // @ts-ignore
      next({ ...to, query: { ...to.query, ...query } });
    } else {
      next();
    }
  });
  if (from.name === to.name) {
    next();
    return;
  }

  if (from.meta.keepState) {
    Promise.all([
      store.dispatch("router/pushQuery", { id: from.name, query: from.query }),
      store.dispatch("router/pushScrollPosition", {
        id: from.name,
        scrollPosition: {
          x: window.pageXOffset,
          y: window.pageYOffset,
        },
      }),
    ]).then(() => next());
    return;
  }

  store.dispatch("router/popQuery", { id: to.name }).then(query => {
    if (to.meta.keepState && query) {
      // @ts-ignore
      next({ ...to, query: { ...to.query, ...query } });
    } else {
      next();
    }
  });
});

router.afterEach(() => {
  // @ts-ignore
  // eslint-disable-next-line no-constant-condition
  if (typeof Shynet !== "undefined") {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    Shynet.newPageLoad();
  }
});

export default router;
