<template>
  <b-step-item>
    <app-form ref="form" :errors="errors" @submit="submit">
      <section class="modal-card-body">
        <app-form-field
          name="digits"
          :rules="{ required: true, digits: 6 }"
          :label="$t('authentication.form.enterCodeFromSentEmail', { email })"
        >
          <app-control-otp
            v-model="digits"
            :length="6"
            @length-reached="triggerSubmit"
          />
        </app-form-field>
        <app-form-errors />
      </section>
      <footer class="modal-card-foot is-justify-content-center">
        <app-button-with-count-down
          ref="resender"
          mode="eager"
          type="is-white"
          @click="resend"
          :loading="isLoading"
          :seconds="60"
          :label-enabled="$t('authentication.form.sendCodeAgain')"
          :label-disabled="createLabelDisabled"
        />
      </footer>
    </app-form>
  </b-step-item>
</template>

<script>
import { extend } from "vee-validate";
import { digits, required } from "vee-validate/dist/rules";

import AppButtonWithCountDown from "@/components/AppButtonWithCountDown";
import AppControlOtp from "@/components/AppControlOtp";
import AppForm from "@/components/AppForm";
import AppFormErrors from "@/components/AppFormErrors";
import AppFormField from "@/components/AppFormField";

extend("digits", digits);
extend("required", required);

export default {
  name: "ModalSignInStepBComplete",
  components: {
    AppFormErrors,
    AppFormField,
    AppForm,
    AppButtonWithCountDown,
    AppControlOtp,
  },
  props: {
    isLoading: Boolean,
    errors: Object,
    email: String,
  },
  data: function () {
    return { digits: "" };
  },
  methods: {
    enter: function () {
      this.$refs.resender.restartCountDown();
    },
    createLabelDisabled(seconds) {
      return this.$t("authentication.form.sendCodeAgainIn", { seconds });
    },
    submit() {
      this.$emit("submitted", {
        payload: { email: this.email, digits: this.digits },
      });
    },
    triggerSubmit() {
      this.$refs.form.submit();
    },
    resend() {
      this.$emit("resend-clicked", {
        payload: { email: this.email },
      });
    },
  },
};
</script>
