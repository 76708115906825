<template>
  <validation-observer ref="observer" v-slot="">
    <form ref="form" @submit.prevent="handleSubmit">
      <slot />
      <input ref="submitter" type="submit" hidden />
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";

export default {
  name: "AppForm",
  components: {
    ValidationObserver,
  },
  props: {
    errors: Object,
  },
  watch: {
    errors(value) {
      this.$refs.observer.setErrors(value);
    },
  },
  methods: {
    async handleSubmit() {
      const observer = this.$refs.observer;
      const isValid = await observer.validate();

      if (isValid) {
        this.$emit("submit");
        return;
      }

      for (const key of Object.keys(observer.fields).sort()) {
        if (observer.fields[key].invalid) {
          observer.refs[key].$el.scrollIntoView({ block: "center" });
          return;
        }
      }
    },
    submit() {
      this.$refs.submitter.click();
    },
  },
};
</script>
