export default class ProgressTracker {
  constructor(progress) {
    this.progress = progress;
  }

  requestHandler(config) {
    this.progress.start();
    return config;
  }

  responseHandler(config) {
    this.progress.finish();
    return config;
  }

  errorHandler(error) {
    const statusCode = (error.response && error.response.status) || 599;

    // Consider 4XX valid responses as well.
    if (statusCode < 500) {
      this.progress.finish();
    } else {
      this.progress.fail();
    }

    return Promise.reject(error);
  }
}
