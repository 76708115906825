import VueI18n from "vue-i18n";

import messagesEn from "@/i18n/lang/en";
import messagesRu from "@/i18n/lang/ru";

const makeI18nMemoized = () => {
  let i18n;

  return () => {
    console.debug("UD / i18n / Accessing memoized");

    if (i18n === undefined) {
      console.debug("UD / i18n / Making new instance");

      i18n = new VueI18n({
        locale: "en",
        messages: { ru: messagesRu, en: messagesEn },
      });
    }

    return i18n;
  };
};

export default makeI18nMemoized();
