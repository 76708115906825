<template>
  <app-form-field name="__all__" :rules="{}" label="" />
</template>

<script>
import AppFormField from "@/components/AppFormField";
export default {
  name: "AppFormErrors",
  components: { AppFormField },
};
</script>
