import { ApiError, errorFromApi } from "@/api/structs";

const unknownError = new ApiError("UNKNOWN_ERROR", "", {}, ["__all__"]);

export const throwApiErrors = (axiosError: any) => {
  const errors =
    axiosError.response &&
    axiosError.response.data &&
    axiosError.response.data["status"] &&
    axiosError.response.data["status"]["errors"] &&
    axiosError.response.data["status"]["errors"].map((error: any) =>
      errorFromApi(error),
    );

  throw errors || [unknownError];
};
