export enum FeatureFlag {
  UserCreateEvents = "ff-1b213e9dd5b44cbc9bb5699695415a24-user-create-events",
}

export enum Modal {
  Menu = "MODAL_MENU",
  SignIn = "MODAL_SIGN_IN",
  CreateCity = "MODAL_CREATE_CITY",
}

export enum Api {
  AuthStart = "AUTH_START",
  AuthComplete = "AUTH_COMPLETE",
  AuthSignOut = "AUTH_SIGN_OUT",

  EventsLoadEvent = "EVENTS_LOAD_EVENT",
  EventsLoadEvents = "EVENTS_LOAD_EVENTS",
  EventsUpdateEventGoing = "EVENTS_UPDATE_EVENT_GOING",
  EventsUpdateEvent = "EVENTS_UPDATE_EVENT",
  EventsCreateEvent = "EVENTS_CREATE_EVENT",

  ChoicesLoadCities = "CHOICES_LOAD_CITIES",
  ChoicesLoadStyles = "CHOICES_LOAD_STYLES",
  ChoicesLoadCountries = "CHOICES_LOAD_COUNTRIES",
  ChoicesCreateCity = "CHOICES_CREATE_CITY",
  ChoicesCreateStyle = "CHOICES_CREATE_STYLE",

  UsersLoadUser = "USERS_LOAD_USER",
  UsersUpdateUser = "USERS_UPDATE_USER",
}

export enum AuthStatus {
  Authenticated = "AUTHENTICATED",
  Anonymous = "ANONYMOUS",
  Unknown = "UNKNOWN",
}
