import { throwApiErrors } from "@/api/errors";
import api from "@/api/instance";
import { attachFromApi, choiceFromApi, eventFromApi, userFromApi } from "@/api/structs";

export function apiGetEvent(id) {
  return api
    .get(`/v1/events/${id}`)
    .then(response => {
      return eventFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}

export function apiGetEvents(
  cityId,
  styleId,
  userIdGoing,
  createdById,
  endDateLte,
  endDateGte,
  startDateLte,
  startDateGte,
  sortBy,
  sortByOrder,
  limit,
  offset,
) {
  return api
    .get("/v1/events", {
      params: {
        cityId: cityId || undefined,
        styleId: styleId || undefined,
        userIdGoing: userIdGoing || undefined,
        createdById: createdById || undefined,
        endDateLte: endDateLte || undefined,
        endDateGte: endDateGte || undefined,
        startDateLte: startDateLte || undefined,
        startDateGte: startDateGte || undefined,
        sortBy: sortBy || undefined,
        sortByOrder: sortByOrder || undefined,
        limit: limit || 1000,
        offset: offset || undefined,
      },
    })
    .then(response => {
      return response.data["data"].map(data => eventFromApi(data));
    })
    .catch(throwApiErrors);
}

export function apiPatchEvent(
  id,
  name,
  summary,
  status,
  description,
  link,
  cover,
  startDate,
  startTime,
  endDate,
  endTime,
  place,
  organizer,
  cityId,
  category,
  stylesIds,
  level,
) {
  return api
    .patch(`/v1/events/${id}`, {
      name,
      summary,
      status,
      description,
      link,
      cover,
      startDate,
      startTime,
      endDate,
      endTime,
      place,
      organizer,
      cityId,
      category,
      stylesIds,
      level,
    })
    .then(response => {
      return eventFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}

export function apiPostEvent(
  name,
  summary,
  status,
  description,
  link,
  cover,
  startDate,
  startTime,
  endDate,
  endTime,
  place,
  organizer,
  iAmOrganizer,
  cityId,
  category,
  stylesIds,
  level,
) {
  return api
    .post(`/v1/events`, {
      name,
      summary,
      status,
      description,
      link,
      cover,
      startDate,
      startTime,
      endDate,
      endTime,
      place,
      organizer,
      iAmOrganizer,
      cityId,
      category,
      stylesIds,
      level,
    })
    .then(response => {
      return eventFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}

export function apiPatchEventGoing(id, going) {
  return api
    .patch(`/v1/events/${id}/going`, { going })
    .then(response => {
      return eventFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}

function apiGetChoices(what) {
  return api
    .get(`/v1/choices/${what}`)
    .then(function (response) {
      return response.data["data"].map(data => choiceFromApi(data));
    })
    .catch(throwApiErrors);
}

export function apiGetChoicesCities() {
  return apiGetChoices("cities");
}

export function apiGetChoicesStyles() {
  return apiGetChoices("styles");
}

export function apiGetChoicesCountries() {
  return apiGetChoices("countries");
}

function apiPostChoices(what, name, groupId) {
  return api
    .post(`/v1/choices/${what}`, {
      name,
      groupId,
    })
    .then(function (response) {
      return choiceFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}

export function apiPostChoicesCity(name, groupId) {
  return apiPostChoices("cities", name, groupId);
}

export function apiPostChoicesStyle(name, groupId) {
  return apiPostChoices("styles", name, groupId);
}

export function apiPostAuthOtpStart(email) {
  return api
    .post("/v1/auth/otp/start", {
      linkTemplate: `${process.env.VUE_APP_URL}/sign-in/<UserEmail>/<Code>`,
      email: email,
    })
    .then(() => null)
    .catch(throwApiErrors);
}

export function apiPostAuthOtpComplete(email, digits) {
  return api
    .post("/v1/auth/otp/complete", {
      email: email,
      digits: digits,
    })
    .then(response => {
      return userFromApi(response.data["data"]["user"]);
    })
    .catch(throwApiErrors);
}

export function apiPostAuthSignOut() {
  return api
    .post("/v1/auth/sign-out")
    .then(() => null)
    .catch(throwApiErrors);
}

export function apiGetUserMe() {
  return api
    .get("/v1/users/me")
    .then(response => {
      return userFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}

export function apiPatchUserMe(fullName, avatarId) {
  return api
    .patch("/v1/users/me", { fullName, avatarId })
    .then(response => {
      return userFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}

export function apiPostUpload(media) {
  const data = new FormData();
  data.append("media", media, media.name);

  return api
    .post("/v1/upload", data)
    .then(response => {
      return attachFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}

export function apiPostUserAvatar(media) {
  const data = new FormData();
  data.append("media", media, media.name);

  return api
    .post("/v1/users/me/avatar", data)
    .then(response => {
      return attachFromApi(response.data["data"]);
    })
    .catch(throwApiErrors);
}
