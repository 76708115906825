import Vue from "vue";
import { Action, Module, Mutation } from "vuex-module-decorators";

import {
  apiGetChoicesCities,
  apiGetChoicesCountries,
  apiGetChoicesStyles,
  apiPostChoicesCity,
  apiPostChoicesStyle,
} from "@/api/requests";
import { Choice } from "@/api/structs";
import { Api } from "@/constants";
import ApiModule, { withStore } from "@/store/api";

interface Choices {
  countries: Choice[];
  cities: Choice[];
  styles: Choice[];
}

interface PayloadAddChoices {
  key: keyof Choices;
  choices: Choice[];
}

interface PayloadCreateChoice {
  name: string;
  groupId: string | null;
}

@Module({ namespaced: true, name: "choices" })
export default class ChoicesModule extends ApiModule {
  public choices: Choices = { cities: [], styles: [], countries: [] };

  @Mutation
  ADD_CHOICES({ key, choices }: PayloadAddChoices) {
    Vue.set<Choice[]>(this.choices, key, choices);
  }

  @Action
  async loadCities(): Promise<void> {
    const choices = await withStore<Choice[]>(
      this.context.commit,
      Api.ChoicesLoadCities,
      apiGetChoicesCities(),
    );

    this.context.commit("ADD_CHOICES", {
      key: "cities",
      choices: choices.sort(compareChoiceWithGroup),
    });
  }

  @Action
  async loadStyles(): Promise<void> {
    const choices = await withStore<Choice[]>(
      this.context.commit,
      Api.ChoicesLoadStyles,
      apiGetChoicesStyles(),
    );

    this.context.commit("ADD_CHOICES", {
      key: "styles",
      choices: choices.sort(compareChoiceWithGroup),
    });
  }

  @Action
  async loadCountries(): Promise<void> {
    const choices = await withStore<Choice[]>(
      this.context.commit,
      Api.ChoicesLoadCountries,
      apiGetChoicesCountries(),
    );

    this.context.commit("ADD_CHOICES", {
      key: "countries",
      choices: choices.sort(compareChoiceWithGroup),
    });
  }

  @Action
  async createCity({ name, groupId }: PayloadCreateChoice) {
    const choice = await withStore<Choice>(
      this.context.commit,
      Api.ChoicesCreateCity,
      apiPostChoicesCity(name, groupId),
    );

    this.context.commit("ADD_CHOICES", {
      key: "cities",
      choices: this.choices["cities"].concat(choice).sort(compareChoiceWithGroup),
    });

    return choice;
  }

  @Action
  async createStyle({ name, groupId }: PayloadCreateChoice) {
    const choice = await withStore<Choice>(
      this.context.commit,
      Api.ChoicesCreateStyle,
      apiPostChoicesStyle(name, groupId),
    );

    this.context.commit("ADD_CHOICES", {
      key: "styles",
      choices: this.choices["styles"].concat(choice).sort(compareChoiceWithGroup),
    });

    return choice;
  }
}

// Remove when https://github.com/nickgashkov/united-dance-product/issues/94 is
// done.
export const compareChoiceWithGroup = (a: Choice, b: Choice): number => {
  const groupNameA = a.group?.name || "";
  const groupNameB = b.group?.name || "";

  const nameA = a.name;
  const nameB = b.name;

  if (groupNameA && !groupNameB) {
    return 1;
  }

  if (!groupNameA && groupNameB) {
    return -1;
  }

  const keyA = (groupNameA + nameA).toLowerCase();
  const keyB = (groupNameB + nameB).toLowerCase();

  return keyA > keyB ? 1 : -1;
};
