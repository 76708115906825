import Vue from "vue";

export const setQuery = (state, payload) => {
  Vue.set(state.queries, payload.id, payload);
};

export const delQuery = (state, payload) => {
  Vue.delete(state.queries, payload.id);
};

export const delQueries = state => {
  state.queries = {};
};

export const setScrollPosition = (state, payload) => {
  Vue.set(state.scrollPositions, payload.id, payload);
};

export const delScrollPosition = (state, payload) => {
  Vue.delete(state.scrollPositions, payload.id);
};

export const delScrollPositions = state => {
  Vue.set(state, "scrollPositions", {});
};
