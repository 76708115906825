








import { MetaInfo } from "vue-meta";
import { Component, Vue } from "vue-property-decorator";

import ModalSignInContainer from "@/containers/ModalSignInContainer.vue";
import SignInNavbarContainer from "@/containers/SignInNavbarContainer.vue";

@Component({
  components: {
    ModalSignInContainer,
    SignInNavbarContainer,
  },
  metaInfo(this: App): MetaInfo {
    return {
      titleTemplate: "%s - united.dance",
      meta: [{ charset: "utf-8" }, { name: "robots", content: "index, follow" }],
    };
  },
})
export default class App extends Vue {}
