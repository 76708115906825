module.exports = {
  validation: {
    vee: {
      digits: "Please enter a {length} digit code",
      email: "Please enter email in format me@example.com",
      required: "Please fill out this field",
      regex: "Please enter only letters, digits and symbols .-_",
      max: "Please enter value no longer than {length} symbols",
      url: "Please enter a valid URL",
    },
    backend: {
      // Generic HTTP errors.
      BAD_REQUEST: "Bad request",
      UNAUTHORIZED: "Unauthorized",
      NOT_FOUND: "Not found",
      UNSUPPORTED_MEDIA_TYPE: "Unsupported media type",
      UNPROCESSABLE_ENTITY: "Unprocessable entity",
      TOO_MANY_REQUESTS: "Too many requests",
      // Everything failed miserably.
      UNKNOWN_ERROR: "Unknown error",
      // Business-logic errors.
      TOO_MANY_OTP_CODES_REQUESTED:
        "Too many codes requested, please try again in {ttl} seconds",
      TOO_MANY_OTP_CODES_ATTEMPTS:
        "Too many attempts to enter the code, please request a new one",
      OTP_CODE_EXPIRED: "The code has expired, please request a new one",
      OTP_CODE_INVALID: "Please enter a {length} digit code",
      OTP_CODE_MISMATCH: "This code is not valid, please try again",
      USERNAME_ALREADY_TAKEN: "This username is already taken, please try another one",
    },
  },
  menu: {
    allEvents: "Feed",
    myEvents: "My events",
    profileEdit: "Edit profile",
  },
  viewsMeta: {
    eventList: {
      title: "Dance events",
      description: "Upcoming events all around the world.",
    },
    myEventList: {
      title: "I'm going",
    },
    profileEdit: {
      title: "Edit profile",
    },
  },
  root: {
    fuckWar: "FOR THE WORLD PEACE",
    errors: {
      pageNotFound: {
        goBackTo: "We couldn't find the page you were looking for, go back to the",
        main: "main page",
      },
    },
    save: "Save",
  },
  features: {
    welcome: "Welcome to experiments",
    userCreateEvents: "User create events",
  },
  authentication: {
    signIn: "Sign in",
    signOut: "Sign out",
    signOutSuccess: "You've signed out",
    form: {
      enterEmailToSignInOrSignUp: "Enter email to sign in",
      recommendToUseNativeBrowser:
        "We recommend using external browsers such as Chrome or Safari to save your sign-in details.",
      enterCodeFromSentEmail: "Please, enter the code sent to {email}",
      sendCode: "Send the code",
      sendCodeAgain: "Resend the code",
      sendCodeAgainIn: "Resend the code in {seconds} seconds",
      hello: "Hi",
      success: "You've signed in",
    },
  },
  users: {
    defaultFullName: "Anonymous",
  },
  profile: {
    form: {
      title: "Edit profile",
      success: "Profile is updated",
      name: "Nickname",
      nameMessage: "Enter your name or nickname.",
    },
  },
  events: {
    my: {
      tabs: {
        going: "Going",
        organizing: "Organizing",
      },
    },
    areYouAnOrganizer: "Are you organizing this event?",
    dropUsALine: "Drop us a line",
    addButton: "Add event",
    goBack: "Back to events",
    iAmGoing: "going",
    goingUsers: "going | going",
    filter: {
      all: "All",
      today: "Today",
      weekend: "Weekend",
    },
    errors: {
      notFound: "There is no such event yet | There are no such events yet",
      notLoaded: "Unable to load event | Unable to load events",
    },
    categories: {
      ball: "ball",
      battle: "battle",
      camp: "camp",
      casting: "casting",
      course: "course",
      festival: "festival",
      intensive: "intensive",
      jam: "jam",
      laboratory: "laboratory",
      lecture: "lecture",
      party: "party",
      show: "show",
      workshop: "workshop",
    },
    when: "When",
    where: "Where",
    organizer: "Organizer",
    category: "Category",
    styles: "Styles",
    forms: {
      createCity: {
        title: "Add a city",
        fields: {
          country: { label: "Country" },
          name: { label: "City" },
        },
        submit: "Add",
      },
    },
    form: {
      addTitle: "Add event",
      editTitle: "Edit event",
      name: "Name",
      summary: "Summary",
      link: "Link",
      linkExample: "https://instagram.com/p/CZ1jQtDNd1o/",
      dates: "Dates",
      place: "Place",
      placeExample: "Dance Club on 10 Norway st.",
      placeExampleOnline: "https://zoom.us/j/5551112222",
      organizer: "Organizer",
      organizerExample: "Summer Dance Forever Committee",
      city: "City",
      category: "Category",
      styles: "Styles",
      publish: "Publish",
      save: "Save",
      addSuccess: "Event is added",
      editSuccess: "Event is edited",
      iAmOrganizer: "I am organizer",
      iAmAttendee: "I am attendee",
      iAmOrganizerMessage: "An organizer can edit the event after publishing.",
    },
  },
  categories: {
    errors: {
      notFound: "There is no such category yet",
    },
  },
  styles: {
    all: "style",
    add: "Add {style}...",
    errors: {
      notFound: "There is no such style yet",
    },
  },
  cities: {
    all: "city",
    add: "Add {city}...",
    errors: {
      notFound: "There is no such city yet",
    },
  },
  periods: {
    until: "until",
  },
  months: {
    short: {
      january: "Jan",
      february: "Feb",
      march: "Mar",
      april: "Apr",
      may: "May",
      june: "Jun",
      july: "Jul",
      august: "Aug",
      september: "Sep",
      october: "Oct",
      november: "Nov",
      december: "Dec",
    },
    long: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
  },
};
