import { LocalDateTime } from "@/datetimes";

export class Attach {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly path: string,
    public readonly link: string,
  ) {}
}

export class EventGoingUsersCount {
  constructor(public readonly number: number, public readonly accuracy: string) {}
}

export class EventGoingUsers {
  constructor(
    public readonly count: EventGoingUsersCount,
    public readonly samples: User[],
  ) {}
}

export class Event {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly summary: string,
    public readonly description: string,
    public readonly link: string,
    public readonly cover: string,
    public readonly coverThumbnail: string,
    public readonly startsAt: LocalDateTime,
    public readonly endsAt: LocalDateTime,
    public readonly place: string,
    public readonly organizer: string,
    public readonly city: string,
    public readonly category: string,
    public readonly styles: string[],
    public readonly level: string,
    public readonly going: boolean,
    public readonly goingUsers: EventGoingUsers,
    public readonly createdBy: string,
  ) {}
}

export class ChoiceGroup {
  constructor(public readonly id: string, public readonly name: string) {}
}

export class Choice {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly group: ChoiceGroup | null,
  ) {}
}

export class User {
  constructor(
    public readonly id: string,
    public readonly fullName: string | null,
    public readonly avatar: Attach | null,
  ) {}
}

export class ApiError {
  constructor(
    public readonly code: string,
    public readonly message: string,
    public readonly details: any,
    public readonly target: string[],
  ) {}
}

export interface ApiErrorsByField {
  [key: string]: string[];
}

export class ApiLoading {
  constructor(
    public readonly isFailed: boolean,
    public readonly isSucceeded: boolean,
    public readonly isPending: boolean,
    public readonly errors: ApiErrorsByField,
  ) {}
}

export const API_LOADING_PLACEHOLDER = new ApiLoading(false, false, false, {});

export function attachFromApi(data: any) {
  return new Attach(data.id, data.name, data.path, data.link);
}

export function eventFromApi(data: any) {
  return new Event(
    data.id,
    data.name,
    data.summary,
    data.description,
    data.link,
    data.cover,
    data.coverThumbnail,
    new LocalDateTime(data.startDate, data.startTime),
    new LocalDateTime(data.endDate, data.endTime),
    data.place,
    data.organizer,
    data.city,
    data.category,
    data.styles,
    data.level,
    data.going,
    new EventGoingUsers(
      new EventGoingUsersCount(
        data.goingUsers.count.number,
        data.goingUsers.count.accuracy,
      ),
      data.goingUsers.samples.map((u: any) => userFromApi(u)),
    ),
    data.createdBy,
  );
}

export function choiceFromApi(data: any) {
  return new Choice(
    data.id,
    data.name,
    data.group ? new ChoiceGroup(data.group.id, data.group.name) : null,
  );
}

export function userFromApi(data: any) {
  return new User(
    data.id,
    data.fullName,
    data.avatar
      ? new Attach(data.avatar.id, data.avatar.name, data.avatar.path, data.avatar.link)
      : null,
  );
}

export function errorFromApi(error: any) {
  return new ApiError(error.code, error.message, error.details, error.target);
}
