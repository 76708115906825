<template>
  <b-button
    v-bind="{ ...$props, ...$attrs }"
    :disabled="isDisabled"
    :label="label"
    @click.prevent="restartAndEmit"
  />
</template>

<script>
export default {
  name: "AppButtonWithCountDown",
  props: {
    // Custom props for this component.
    seconds: Number,
    labelDisabled: Function,
    labelEnabled: String,
  },
  data: function () {
    return {
      currentSeconds: 0,
    };
  },
  computed: {
    isDisabled() {
      return this.currentSeconds > 0;
    },
    label() {
      return this.isDisabled
        ? this.labelDisabled(this.currentSeconds)
        : this.labelEnabled;
    },
  },
  methods: {
    restartAndEmit() {
      this.restartCountDown();
      this.$emit("click");
    },
    restartCountDown() {
      this.currentSeconds = this.seconds;
      this.countDown();
    },
    countDown() {
      if (this.currentSeconds > 0) {
        setTimeout(() => {
          this.currentSeconds -= 1;
          this.countDown();
        }, 1000);
      }
    },
  },
};
</script>
