export function translateCategory($t, category) {
  return {
    ball: $t("events.categories.ball"),
    battle: $t("events.categories.battle"),
    camp: $t("events.categories.camp"),
    casting: $t("events.categories.casting"),
    course: $t("events.categories.course"),
    festival: $t("events.categories.festival"),
    intensive: $t("events.categories.intensive"),
    jam: $t("events.categories.jam"),
    laboratory: $t("events.categories.laboratory"),
    lecture: $t("events.categories.lecture"),
    party: $t("events.categories.party"),
    show: $t("events.categories.show"),
    workshop: $t("events.categories.workshop"),
  }[category];
}

export function translateMonth($t, format, number) {
  return {
    short: {
      1: $t("months.short.january"),
      2: $t("months.short.february"),
      3: $t("months.short.march"),
      4: $t("months.short.april"),
      5: $t("months.short.may"),
      6: $t("months.short.june"),
      7: $t("months.short.july"),
      8: $t("months.short.august"),
      9: $t("months.short.september"),
      10: $t("months.short.october"),
      11: $t("months.short.november"),
      12: $t("months.short.december"),
    },
    long: {
      1: $t("months.long.january"),
      2: $t("months.long.february"),
      3: $t("months.long.march"),
      4: $t("months.long.april"),
      5: $t("months.long.may"),
      6: $t("months.long.june"),
      7: $t("months.long.july"),
      8: $t("months.long.august"),
      9: $t("months.long.september"),
      10: $t("months.long.october"),
      11: $t("months.long.november"),
      12: $t("months.long.december"),
    },
  }[format][number];
}

export function translateValidationVee($t, rule, values) {
  return {
    email: $t("validation.vee.email", values),
    required: $t("validation.vee.required", values),
    digits: $t("validation.vee.digits", values),
    regex: $t("validation.vee.regex", values),
    max: $t("validation.vee.max", values),
    url: $t("validation.vee.url", values),
  }[rule];
}

export function translateValidationBackend($t, code, details) {
  // prettier-ignore
  return {
    BAD_REQUEST: $t("validation.backend.BAD_REQUEST", details),
    UNAUTHORIZED: $t("validation.backend.UNAUTHORIZED", details),
    NOT_FOUND: $t("validation.backend.NOT_FOUND", details),
    UNSUPPORTED_MEDIA_TYPE: $t("validation.backend.UNSUPPORTED_MEDIA_TYPE", details),
    UNPROCESSABLE_ENTITY: $t("validation.backend.UNPROCESSABLE_ENTITY", details),
    UNKNOWN_ERROR: $t("validation.backend.UNKNOWN_ERROR", details),
    TOO_MANY_REQUESTS: $t("validation.backend.TOO_MANY_REQUESTS", details),
    TOO_MANY_OTP_CODES_REQUESTED: $t("validation.backend.TOO_MANY_OTP_CODES_REQUESTED", details),
    TOO_MANY_OTP_CODES_ATTEMPTS: $t("validation.backend.TOO_MANY_OTP_CODES_ATTEMPTS", details),
    OTP_CODE_EXPIRED: $t("validation.backend.OTP_CODE_EXPIRED", details),
    OTP_CODE_INVALID: $t("validation.backend.OTP_CODE_INVALID", details),
    OTP_CODE_MISMATCH: $t("validation.backend.OTP_CODE_MISMATCH", details),
    USERNAME_ALREADY_TAKEN: $t("validation.backend.USERNAME_ALREADY_TAKEN", details),
  }[code];
}
