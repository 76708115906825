import "@/assets/styles/main.scss";

import { configure } from "vee-validate";
import Vue from "vue";
import { CreateElement } from "vue/types/vue";

import App from "@/App.vue";
import configureVue from "@/configure.js";
import { translateValidationVee } from "@/i18n/keys-dynamic.js";
import makeI18n from "@/i18n/plugin.js";
import router from "@/router";
import store from "@/store";

const vue = configureVue(Vue);
const i18n = makeI18n();

// Has to be done here because of some `i18n` weirdness. If it is declared
// before `vue` configuration, everything blows up.
configure({
  defaultMessage: (field, values) => {
    return translateValidationVee(i18n.t.bind(i18n), values._rule_, values);
  },
});

new vue({
  router,
  store,
  i18n,
  render: (h: CreateElement) => h(App),
}).$mount("#app");
