<template>
  <b-dropdown
    position="is-bottom-left"
    :mobile-modal="false"
    :expanded="false"
    append-to-body
    aria-role="menu"
  >
    <template #trigger>
      <div class="level-item" style="cursor: pointer">
        <div class="is-flex is-align-items-center">
          <span>{{ fullName || defaultFullName }}</span>
          <app-avatar
            class="ml-3"
            :alt="fullName || defaultFullName"
            :avatar="avatar"
            :size="24"
          />
          <font-awesome-icon class="ml-3" :icon="['fas', 'caret-down']" />
        </div>
      </div>
    </template>

    <div v-for="item in items" :key="item.id">
      <hr class="dropdown-divider" v-if="item.type === 'divider'" />
      <b-dropdown-item @click="item.onClick" v-else>
        {{ item.name }}
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
export default {
  name: "TheMenuDropdown",
  components: { AppAvatar },
  props: {
    fullName: String,
    avatar: Object,
    items: Array,
  },
  computed: {
    defaultFullName() {
      return this.$t("users.defaultFullName");
    },
  },
};
</script>
