<template>
  <b-modal v-bind="{ ...$props, ...$attrs }">
    <template #default>
      <slot />
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "AppModal",
  props: {
    // Same props as in the `BModal`.
    onCancel: Function,
    // `v-model`.
    value: {
      validator: () => true,
    },
    // Custom props for this component.
    title: String,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/utilities";

.modal::v-deep {
  .modal-card {
    @include mobile {
      width: initial;
      margin: 0 $modal-margin-side 0 $modal-margin-side;
    }
  }
}
</style>
