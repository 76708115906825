import { translateMonth } from "@/i18n/keys-dynamic";

export const MONTH_FORMAT_SHORT = "short";
export const MONTH_FORMAT_LONG = "long";

/** Represents a datetime object w/o timezone. */
export class LocalDateTime {
  /**
   * Create a local datetime.
   * @param dateString - The date in ISO format YYYY-MM-DD, e.g. 2012-12-20.
   * @param timeString - The time in ISO format hh:mm:ss, e.g. 23:59:59.
   */
  constructor(dateString, timeString) {
    this.dateString = dateString;
    this.timeString = timeString;
  }
  getDate() {
    return new Date(this.dateString);
  }
  getMonth($t, monthFormat) {
    return translateMonth($t, monthFormat, parseInt(this.dateString.slice(5, 7)));
  }
  getDay() {
    return parseInt(this.dateString.slice(8)).toString();
  }
  getTime() {
    return (this.timeString && this.timeString.slice(0, 5)) || "";
  }
}

export function getHorizontalRange(
  startLocalDateTime,
  endLocalDateTime,
  $t,
  monthFormat,
) {
  const startDay = startLocalDateTime.getDay();
  const startMonth = startLocalDateTime.getMonth($t, monthFormat);
  const startTime = startLocalDateTime.getTime();

  const endDay = endLocalDateTime.getDay();
  const endMonth = endLocalDateTime.getMonth($t, monthFormat);
  const endTime = endLocalDateTime.getTime();

  const isDaySame = startDay === endDay;
  const isMonthSame = startMonth === endMonth;
  const isTimeSame = startTime === endTime;

  const hasTime = startTime || endTime;
  const parts = [];

  if (isMonthSame && isDaySame && isTimeSame) {
    parts.push(startDay, startMonth);
    if (startTime) {
      parts.push(startTime);
    }
  } else if (isMonthSame && isDaySame) {
    parts.push(startDay, startMonth);
    if (startTime) {
      parts.push(startTime);
    }
    if (startTime && endTime) {
      parts.push("—");
    } else if (!startTime && endTime) {
      parts.push($t("periods.until"));
    }
    if (endTime) {
      parts.push(endTime);
    }
  } else if (isMonthSame && !hasTime) {
    parts.push(startDay, "—", endDay, startMonth);
  } else {
    parts.push(startDay, startMonth, startTime, "—", endDay, endMonth, endTime);
  }

  return parts.filter(v => v).join(" ");
}

export function getVerticalRange(
  startLocalDateTime,
  endLocalDateTime,
  $t,
  monthFormat,
) {
  const startDay = startLocalDateTime.getDay();
  const startMonth = startLocalDateTime.getMonth($t, monthFormat);

  const endDay = endLocalDateTime.getDay();
  const endMonth = endLocalDateTime.getMonth($t, monthFormat);

  const isDaySame = startDay === endDay;
  const isMonthSame = startMonth === endMonth;

  if (isMonthSame && isDaySame) {
    return {
      top: {
        day: startDay,
        month: startMonth,
      },
      bottom: null,
    };
  } else if (isMonthSame) {
    return {
      top: {
        day: `${startDay}-${endDay}`,
        month: startMonth,
      },
      bottom: null,
    };
  }

  return {
    top: {
      day: startDay,
      month: startMonth,
    },
    bottom: {
      day: endDay,
      month: endMonth,
    },
  };
}

export function getTodayISO() {
  return new Date().toISOString().substring(0, 10);
}

export function getWeekendStartISO() {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const last = first + 4;

  const sunday = new Date(today.setDate(last));

  return sunday.toISOString().substring(0, 10);
}

export function getWeekendEndISO() {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const last = first + 6;

  const sunday = new Date(today.setDate(last));

  return sunday.toISOString().substring(0, 10);
}
