import { Action, Module, Mutation } from "vuex-module-decorators";

import { apiGetUserMe, apiPatchUserMe } from "@/api/requests";
import { User } from "@/api/structs";
import { Api, AuthStatus } from "@/constants";
import ApiModule, { withStore } from "@/store/api";

interface PayloadUpdateUser {
  fullName: string;
  avatarId?: string; // uuid
}

@Module({ namespaced: true, name: "users" })
export default class UsersModule extends ApiModule {
  public user: User | null = null;

  @Mutation
  SET_USER(user: User) {
    this.user = user;
  }

  @Action
  async loadUser() {
    let user;
    let status;

    try {
      status = AuthStatus.Authenticated;
      user = await withStore<User>(
        this.context.commit,
        Api.UsersLoadUser,
        apiGetUserMe(),
      );
    } catch (e) {
      status = AuthStatus.Anonymous;
      user = null;
    }

    this.context.commit("SET_USER", user);
    this.context.commit("auth/SET_AUTH_STATUS", status, { root: true });
  }

  @Action
  async updateUser({ fullName, avatarId }: PayloadUpdateUser) {
    const user = await withStore<User>(
      this.context.commit,
      Api.UsersUpdateUser,
      apiPatchUserMe(fullName, avatarId),
    );

    this.context.commit("SET_USER", user);
  }
}
