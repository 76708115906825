<template>
  <section class="hero is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">404</h1>
        <h2 class="subtitle">
          {{ $t("root.errors.pageNotFound.goBackTo") }}
          <router-link :to="{ name: 'home' }">{{
            $t("root.errors.pageNotFound.main")
          }}</router-link>
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFoundView",
  metaInfo: {
    title: "404",
  },
};
</script>

<style scoped lang="scss">
a {
  border-bottom: 1px solid currentColor;
}
</style>
