<template>
  <app-modal
    :active="isOpen"
    :can-cancel="false"
    :has-modal-card="true"
    :trap-focus="true"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t("authentication.signIn") }}</p>
        <button type="button" class="delete" @click="$emit('cancelled')" />
      </header>
      <app-steps ref="steps" :has-navigation="false" :animated="false">
        <modal-sign-in-step-a-start
          :is-loading="start.isLoading"
          :errors="start.errors"
          @submitted="submitStart"
        />
        <modal-sign-in-step-b-complete
          ref="b"
          :is-loading="complete.isLoading"
          :errors="complete.errors"
          :email="complete.email"
          @submitted="submitComplete"
          @resend-clicked="resendComplete"
        />
        <modal-sign-in-step-c-profile-edit
          ref="c"
          :is-loading="profileEdit.isLoading"
          :errors="profileEdit.errors"
          :user="profileEdit.user"
          @submitted="submitProfileEdit"
        />
      </app-steps>
    </div>
  </app-modal>
</template>

<script>
import AppModal from "@/components/AppModal";
import AppSteps from "@/components/AppSteps";
import ModalSignInStepAStart from "@/components/ModalSignInStepAStart";
import ModalSignInStepBComplete from "@/components/ModalSignInStepBComplete";
import ModalSignInStepCProfileEdit from "@/components/ModalSignInStepCProfileEdit";

export default {
  name: "ModalSignIn",
  components: {
    ModalSignInStepCProfileEdit,
    AppModal,
    AppSteps,
    ModalSignInStepAStart,
    ModalSignInStepBComplete,
  },
  props: {
    isOpen: Boolean,
    start: Object,
    complete: Object,
    profileEdit: Object,
  },
  methods: {
    submitStart($event) {
      this.$emit("start-submitted", {
        payload: $event.payload,
        proceed: () => {
          this.$refs.b.enter();
          this.$refs.steps.next();
        },
      });
    },
    submitComplete($event) {
      this.$emit("complete-submitted", {
        payload: $event.payload,
        proceed: () => {
          if (!this.profileEdit.user.fullName) {
            this.$refs.steps.next();
          } else {
            this.$emit("cancelled");
            this.$buefy.snackbar.open({
              message: this.$t("authentication.form.success").toString(),
            });
          }
        },
      });
    },
    resendComplete($event) {
      this.$emit("complete-resend-clicked", {
        payload: $event.payload,
      });
    },
    submitProfileEdit($event) {
      this.$emit("profile-edit-submitted", {
        payload: $event.payload,
        proceed: () => {
          this.$emit("cancelled");
          this.$buefy.snackbar.open({
            message: this.$t("authentication.form.success").toString(),
          });
        },
      });
    },
  },
};
</script>
