module.exports = {
  validation: {
    vee: {
      digits: "Укажите код из {length} цифр",
      email: "Укажите почту в формате me@example.com",
      required: "Обязательное поле",
      regex: "Только латинские буквы, цифры и символы .-_",
      max: "Укажите значение длиной не более {length} символов",
      url: "Укажите верный URL",
    },
    backend: {
      // Generic HTTP errors.
      BAD_REQUEST: "Некорректный запрос",
      UNAUTHORIZED: "Не авторизован",
      NOT_FOUND: "Не найдено",
      UNSUPPORTED_MEDIA_TYPE: "Неподдерживаемый тип данных",
      UNPROCESSABLE_ENTITY: "Необрабатываемый экземпляр",
      TOO_MANY_REQUESTS: "Слишком много запросов",
      // Everything failed miserably.
      UNKNOWN_ERROR: "Неизвестная ошибка",
      // Business-logic errors.
      TOO_MANY_OTP_CODES_REQUESTED:
        "Вы запросили слишком много кодов, попробуйте еще раз через {ttl} " + "секунд",
      TOO_MANY_OTP_CODES_ATTEMPTS:
        "Вы попытались ввести код слишком много раз, запросите новый",
      OTP_CODE_EXPIRED: "Срок действия кода истек, запросите новый",
      OTP_CODE_INVALID: "Укажите код из {length} цифр",
      OTP_CODE_MISMATCH: "Неверный код",
      USERNAME_ALREADY_TAKEN: "Это имя пользователя уже занято",
    },
  },
  menu: {
    allEvents: "Афиша",
    myEvents: "Мои ивенты",
    profileEdit: "Редактировать профиль",
  },
  viewsMeta: {
    eventList: {
      title: "Танцевальные мероприятия",
      description: "Список предстоящих мероприятий в городах России и мира.",
    },
    myEventList: {
      title: "Я иду",
    },
    profileEdit: {
      title: "Настройки",
    },
  },
  root: {
    fuckWar: "МЫ ЗА МИР НА ЗЕМЛЕ",
    errors: {
      pageNotFound: {
        goBackTo: "Cтраница не найдена, вернуться на",
        main: "главную",
      },
    },
    save: "Сохранить",
  },
  features: {
    welcome: "Добро пожаловать в эксперименты",
    userCreateEvents: "Добавление ивентов",
  },
  authentication: {
    signIn: "Войти",
    signOut: "Выйти",
    signOutSuccess: "Выход выполнен",
    form: {
      enterEmailToSignInOrSignUp: "Введите почту, чтобы войти или зарегистрироваться",
      recommendToUseNativeBrowser:
        "Рекомендуем заходить с внешнего браузера, например Chrome или Safari, чтобы сохранить данные для входа",
      enterCodeFromSentEmail: "Введите код из письма, отправленного на {email}",
      sendCode: "Отправить код",
      sendCodeAgain: "Отправить еще раз",
      sendCodeAgainIn: "Отправить еще раз через {seconds}",
      hello: "Привет",
      success: "Вход выполнен",
    },
  },
  users: {
    defaultFullName: "Анонимус",
  },
  profile: {
    form: {
      title: "Редактировать профиль",
      success: "Профиль отредактирован",
      name: "Никнейм",
      nameMessage: "Введите ваше имя или никнейм",
    },
  },
  events: {
    my: {
      tabs: {
        going: "Иду",
        organizing: "Организую",
      },
    },
    areYouAnOrganizer: "Вы организатор этого ивента?",
    dropUsALine: "Напишите нам",
    addButton: "Добавить ивент",
    goBack: "К ивентам",
    iAmGoing: "я иду",
    goingUsers: "идет | идут",
    filter: {
      all: "Все",
      today: "Сегодня",
      weekend: "Выходные",
    },
    errors: {
      notFound: "Такого ивента пока нет | Таких ивентов пока нет",
      notLoaded: "Не удалось загрузить ивент | Не удалось загрузить ивенты",
    },
    categories: {
      ball: "ball",
      battle: "battle",
      camp: "camp",
      casting: "casting",
      course: "course",
      festival: "festival",
      intensive: "intensive",
      jam: "jam",
      laboratory: "laboratory",
      lecture: "lecture",
      party: "party",
      show: "show",
      workshop: "workshop",
    },
    when: "Когда",
    where: "Где",
    organizer: "Организатор",
    category: "Категория",
    styles: "Стили",
    forms: {
      createCity: {
        title: "Добавить город",
        fields: {
          country: { label: "Страна" },
          name: { label: "Город" },
        },
        submit: "Добавить",
      },
    },
    form: {
      addTitle: "Добавить ивент",
      editTitle: "Изменить ивент",
      name: "Название",
      summary: "Краткое описание",
      link: "Ссылка",
      linkExample: "https://t.me/united_dance",
      dates: "Даты проведения",
      place: "Место",
      placeExample: "ДК Выборгский на ул. Комиссара Смирнова 15",
      placeExampleOnline: "https://zoom.us/j/5551112222",
      organizer: "Организатор",
      organizerExample: "Summer Dance Forever Committee",
      city: "Город",
      category: "Категория",
      styles: "Стили",
      publish: "Опубликовать",
      save: "Сохранить",
      addSuccess: "Ивент добавлен",
      editSuccess: "Ивент изменен",
      iAmOrganizer: "Я организатор",
      iAmAttendee: "Я участник",
      iAmOrganizerMessage: "Организатор может редактировать ивент после публикации.",
    },
  },
  categories: {
    errors: {
      notFound: "Такой категории пока нет",
    },
  },
  styles: {
    all: "все стили",
    add: "Добавить {style}...",
    errors: {
      notFound: "Такого стиля пока нет",
    },
  },
  cities: {
    all: "все города",
    add: "Добавить {city}...",
    errors: {
      notFound: "Такого города пока нет",
    },
  },
  periods: {
    until: "до",
  },
  months: {
    short: {
      january: "янв",
      february: "фев",
      march: "мар",
      april: "апр",
      may: "мая",
      june: "июн",
      july: "июл",
      august: "авг",
      september: "сен",
      october: "окт",
      november: "ноя",
      december: "дек",
    },
    long: {
      january: "января",
      february: "февраля",
      march: "марта",
      april: "апреля",
      may: "мая",
      june: "июня",
      july: "июля",
      august: "августа",
      september: "сентября",
      october: "октября",
      november: "ноября",
      december: "декабря",
    },
  },
};
