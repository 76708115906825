<template>
  <b-input
    size="is-large"
    placeholder="------"
    v-model="valueProxy"
    :minlength="length"
    :maxlength="length"
    required
    :has-counter="false"
    :status-icon="false"
    type="text"
    inputmode="numeric"
    autocomplete="one-time-code"
    @keypress.native="onlyNumbers"
  />
</template>

<script>
export default {
  name: "AppControlOtp",
  props: {
    label: String,
    length: Number,
    // `v-model`.
    value: {
      validator: () => true,
    },
  },
  watch: {
    valueProxy: function (value) {
      if (value.length === this.length) {
        this.$emit("length-reached", value);
      }
    },
  },
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onlyNumbers($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.control::v-deep {
  .input {
    font-variant-numeric: tabular-nums;
    text-indent: 1rem;
    text-align: center;
    letter-spacing: 1rem;
  }
  .input:placeholder-shown {
    caret-color: transparent;
  }
}
</style>
