import Vue from "vue";
import { Mutation, VuexModule } from "vuex-module-decorators";

import { ApiError, ApiLoading } from "@/api/structs";
import { translateValidationBackend } from "@/i18n/keys-dynamic";
import makeI18n from "@/i18n/plugin";

enum ApiStatus {
  Pending = "PENDING",
  Success = "SUCCESS",
  Failure = "FAILURE",
}

interface ApiStatusByKey {
  [key: string]: ApiStatus;
}

interface ApiErrorsByKey {
  [key: string]: ApiError[];
}

interface ApiErrorsByField {
  [key: string]: string[];
}

export async function withStore<T>(
  commit: (type: string, payload: any) => void,
  key: string,
  promise: Promise<T>,
): Promise<T> {
  commit("REMOVE_API_ERRORS_BY_KEY", { key: key });
  commit("ADD_API_STATUS_BY_KEY", {
    key: key,
    apiStatus: ApiStatus.Pending,
  });

  try {
    const result = await promise;

    commit("REMOVE_API_ERRORS_BY_KEY", { key: key });
    commit("ADD_API_STATUS_BY_KEY", {
      key: key,
      apiStatus: ApiStatus.Success,
    });

    return result;
  } catch (e) {
    commit("ADD_API_ERRORS_BY_KEY", {
      key: key,
      apiErrors: e,
    });
    commit("ADD_API_STATUS_BY_KEY", {
      key: key,
      apiStatus: ApiStatus.Failure,
    });

    throw e;
  }
}

interface PayloadMutationAddApiStatus {
  key: string;
  apiStatus: ApiStatus;
}

interface PayloadMutationAddApiErrors {
  key: string;
  apiErrors: ApiError[];
}

interface PayloadMutationRemoveApiErrors {
  key: string;
}

export default abstract class ApiModule extends VuexModule {
  protected _apiStatusByKey: ApiStatusByKey = {};
  protected _apiErrorsByKey: ApiErrorsByKey = {};

  get isPending(): (key: string) => boolean {
    return key => {
      return this._apiStatusByKey[key] === ApiStatus.Pending;
    };
  }

  get isSucceeded(): (key: string) => boolean {
    return this.isSuccess;
  }
  get isFailed(): (key: string) => boolean {
    return this.isFailure;
  }

  get loading(): (key: string) => ApiLoading {
    return key => {
      return new ApiLoading(
        this.isFailed(key),
        this.isSucceeded(key),
        this.isPending(key),
        this.getErrors(key),
      );
    };
  }

  get isSuccess(): (key: string) => boolean {
    return key => {
      return this._apiStatusByKey[key] === ApiStatus.Success;
    };
  }

  get isFailure(): (key: string) => boolean {
    return key => {
      return this._apiStatusByKey[key] === ApiStatus.Failure;
    };
  }

  get getErrors(): (key: string) => ApiErrorsByField {
    return key => {
      return errorsByField(this._apiErrorsByKey[key] || []);
    };
  }

  @Mutation
  ADD_API_STATUS_BY_KEY({ key, apiStatus }: PayloadMutationAddApiStatus) {
    Vue.set<ApiStatus>(this._apiStatusByKey, key, apiStatus);
  }

  @Mutation
  ADD_API_ERRORS_BY_KEY({ key, apiErrors }: PayloadMutationAddApiErrors) {
    Vue.set<ApiError[]>(this._apiErrorsByKey, key, apiErrors);
  }

  @Mutation
  REMOVE_API_ERRORS_BY_KEY({ key }: PayloadMutationRemoveApiErrors) {
    Vue.delete(this._apiErrorsByKey, key);
  }
}

const errorsByField = (apiErrors: ApiError[]): ApiErrorsByField => {
  const $t = makeI18n();
  const obj: ApiErrorsByField = {};

  for (let i = 0; i < apiErrors.length; i++) {
    for (let j = 0; j < apiErrors[i].target.length; j++) {
      if (!Object.prototype.hasOwnProperty.call(obj, apiErrors[i].target[j])) {
        obj[apiErrors[i].target[j]] = [];
      }

      obj[apiErrors[i].target[j]].push(
        translateValidationBackend(
          $t.t.bind($t),
          apiErrors[i].code,
          apiErrors[i].details,
        ),
      );
    }
  }
  return obj;
};
