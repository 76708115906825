
































import "reflect-metadata";

import { Component, Prop, Vue } from "vue-property-decorator";

import { Attach } from "@/api/structs";

@Component
export default class AppAvatar extends Vue {
  @Prop({ default: "" }) readonly alt!: string;
  @Prop({ required: true }) readonly avatar!: Attach | null;
  @Prop({ required: true }) readonly size!: 24 | 48 | 96;
  @Prop({ default: "", required: false }) readonly background!: string;

  get sizeClass() {
    return `is-${this.size}x${this.size}`;
  }

  get textSizeClass() {
    if (this.size !== 24) {
      return "";
    }

    return "is-size-7";
  }

  get colorClass() {
    const sum =
      this.letters[0].toUpperCase().charCodeAt(0) +
      this.letters[1].toUpperCase().charCodeAt(0) +
      this.letters[2].toUpperCase().charCodeAt(0);

    const index = sum % 13;

    return `has-background-avatar-missing-${index}`;
  }

  get letters() {
    if (!this.alt) {
      return ["A", "A", "A"];
    }

    if (this.alt.length === 1) {
      return [this.alt[0], this.alt[0], this.alt[0]];
    }

    if (this.alt.length === 2) {
      return [this.alt[0], this.alt[1], this.alt[0]];
    }

    return [this.alt[0], this.alt[1], this.alt[2]];
  }
}
