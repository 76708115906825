<template>
  <div>
    <the-menu-modal v-bind="{ ...$props }" v-if="$mq === 'tablet'" />
    <the-menu-dropdown v-bind="{ ...$props }" v-else />
  </div>
</template>

<script>
import TheMenuDropdown from "@/components/TheMenuDropdown";
import TheMenuModal from "@/components/TheMenuModal";
export default {
  name: "TheMenu",
  components: { TheMenuModal, TheMenuDropdown },
  props: {
    fullName: String,
    avatar: Object,
    items: Array,
    isOpen: Boolean,
    openModal: Function,
    closeModal: Function,
  },
};
</script>
