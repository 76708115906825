import Vue from "vue";
import { Action, Module, Mutation } from "vuex-module-decorators";

import {
  apiGetEvent,
  apiGetEvents,
  apiPatchEvent,
  apiPatchEventGoing,
  apiPostEvent,
} from "@/api/requests";
import { Event } from "@/api/structs";
import { Api } from "@/constants";
import ApiModule, { withStore } from "@/store/api";

interface PayloadLoadEvent {
  id: string; // uuid
}

interface PayloadLoadEvents {
  cityId: string;
  styleId: string;
  userIdGoing?: string;
  createdById?: string;
  endDateLte?: string;
  endDateGte?: string;
  startDateLte?: string;
  startDateGte?: string;
  sortBy: string;
  sortByOrder: string;
  limit: string;
  offset: string;
}

interface PayloadUpdateEvent {
  id: string; // uuid
  name: string;
  summary: string;
  status: string; // published or draft
  description: string;
  link: string; // url
  cover: string; // path
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  place: string;
  organizer: string;
  cityId: string;
  category: string;
  stylesIds: string;
  level: string;
}

interface PayloadCreateEvent {
  name: string;
  summary: string;
  status: string; // published or draft
  description: string;
  link: string; // url
  cover: string; // path
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  place: string;
  organizer: string;
  iAmOrganizer: boolean;
  cityId: string;
  category: string;
  stylesIds: string;
  level: string;
}

interface PayloadUpdateEventGoing {
  id: string;
  going: boolean;
}

@Module({ namespaced: true, name: "events" })
export default class EventsModule extends ApiModule {
  public event: Event | null = null;
  public events: Event[] = [];

  @Mutation
  SET_EVENT(event: Event) {
    this.event = event;
  }

  @Mutation
  SET_EVENTS(events: Event[]) {
    this.events = events;
  }

  @Mutation
  REPLACE_EVENT(event: Event) {
    const index = this.events.findIndex(e => e.id === event.id);

    if (~index) {
      Vue.set<Event>(this.events, index, event);
    }

    if (this.event?.id === event.id) {
      this.event = event;
    }
  }

  @Action
  async loadEvent({ id }: PayloadLoadEvent) {
    const event = await withStore<Event>(
      this.context.commit,
      Api.EventsLoadEvent,
      apiGetEvent(id),
    );

    this.context.commit("SET_EVENT", event);
  }

  @Action
  async loadEvents({
    cityId,
    styleId,
    userIdGoing,
    createdById,
    endDateLte,
    endDateGte,
    startDateLte,
    startDateGte,
    sortBy,
    sortByOrder,
    limit,
    offset,
  }: PayloadLoadEvents) {
    const events = await withStore<Event[]>(
      this.context.commit,
      Api.EventsLoadEvents,
      apiGetEvents(
        cityId,
        styleId,
        userIdGoing,
        createdById,
        endDateLte,
        endDateGte,
        startDateLte,
        startDateGte,
        sortBy,
        sortByOrder,
        limit,
        offset,
      ),
    );

    this.context.commit("SET_EVENTS", events);
  }

  @Action
  async createEvent({
    name,
    summary,
    status,
    description,
    link,
    cover,
    startDate,
    startTime,
    endDate,
    endTime,
    place,
    organizer,
    iAmOrganizer,
    cityId,
    category,
    stylesIds,
    level,
  }: PayloadCreateEvent) {
    const event = await withStore<Event>(
      this.context.commit,
      Api.EventsCreateEvent,
      apiPostEvent(
        name,
        summary,
        status,
        description,
        link,
        cover,
        startDate,
        startTime,
        endDate,
        endTime,
        place,
        organizer,
        iAmOrganizer,
        cityId,
        category,
        stylesIds,
        level,
      ),
    );

    this.context.commit("SET_EVENT", event);
  }

  @Action
  async updateEvent({
    id,
    name,
    summary,
    status,
    description,
    link,
    cover,
    startDate,
    startTime,
    endDate,
    endTime,
    place,
    organizer,
    cityId,
    category,
    stylesIds,
    level,
  }: PayloadUpdateEvent) {
    const event = await withStore<Event>(
      this.context.commit,
      Api.EventsUpdateEvent,
      apiPatchEvent(
        id,
        name,
        summary,
        status,
        description,
        link,
        cover,
        startDate,
        startTime,
        endDate,
        endTime,
        place,
        organizer,
        cityId,
        category,
        stylesIds,
        level,
      ),
    );

    this.context.commit("SET_EVENT", event);
  }

  @Action
  async updateEventGoing({ id, going }: PayloadUpdateEventGoing) {
    const event = await withStore<Event>(
      this.context.commit,
      `${Api.EventsUpdateEventGoing}--${id}`,
      apiPatchEventGoing(id, going),
    );

    this.context.commit("REPLACE_EVENT", event);
  }
}
