
















import { Component, Vue } from "vue-property-decorator";

import SignInNavbar from "@/components/SignInNavbar.vue";
import { Modal } from "@/constants";
import { authStore, modalsStore, usersStore } from "@/store";

@Component({ components: { SignInNavbar } })
export default class SignInNavbarContainer extends Vue {
  get authStore() {
    return authStore;
  }
  get usersStore() {
    return usersStore;
  }
  get menuItems() {
    const pushOrNoop = (name: string) => {
      if (this.$route.name !== name) {
        this.$router.push({ name: name }).then(() => modalsStore.close(Modal.Menu));
      } else {
        modalsStore.close(Modal.Menu);
      }
    };
    return [
      {
        id: "all-events",
        type: "link",
        name: this.$t("menu.allEvents"),
        onClick: () => pushOrNoop("home"),
      },
      {
        id: "my-events",
        type: "link",
        name: this.$t("menu.myEvents"),
        onClick: () => pushOrNoop("events-my"),
      },
      {
        id: "profile-edit-divider",
        type: "divider",
        name: "",
        onClick: () => {},
      },
      {
        id: "profile-edit",
        type: "link",
        name: this.$t("menu.profileEdit"),
        onClick: () => pushOrNoop("profile-edit"),
      },
      {
        id: "sign-out-divider",
        type: "divider",
        name: "",
        onClick: () => {},
      },
      {
        id: "sign-out",
        name: this.$t("authentication.signOut"),
        onClick: () => {
          this.signOut();
          this.$buefy.snackbar.open({
            message: this.$t("authentication.signOutSuccess").toString(),
          });
          pushOrNoop("home");
        },
      },
    ];
  }
  get isOpen() {
    return modalsStore.isOpen(Modal.Menu);
  }

  flushRouter() {
    this.$store.dispatch("router/flush");
  }
  signOut() {
    authStore.signOut();
  }
  openModalSignIn() {
    modalsStore.open(Modal.SignIn);
  }
  openModal() {
    modalsStore.open(Modal.Menu);
  }
  closeModal() {
    modalsStore.close(Modal.Menu);
  }
}
