<template>
  <b-step-item>
    <app-form ref="form" :errors="errors" @submit="submit">
      <section class="modal-card-body">
        <h2 class="is-size-5 has-text-centered">
          <strong>{{ $t("authentication.form.hello") }}</strong>
        </h2>
        <div class="mt-3">
          <app-form-field
            style="width: 100%"
            name="fullName"
            :label="$t('profile.form.name')"
            :rules="{ required: true, max: 30 }"
            :message="$t('profile.form.nameMessage')"
          >
            <b-input lazy v-model="fullName" :status-icon="false" />
          </app-form-field>
        </div>
        <app-form-errors />
      </section>
      <footer class="modal-card-foot is-justify-content-center">
        <app-form-submit :loading="isLoading" :label="$t('root.save')" />
      </footer>
    </app-form>
  </b-step-item>
</template>

<script>
import { extend } from "vee-validate";
import { max, regex, required } from "vee-validate/dist/rules";

import { User } from "@/api/structs";
import AppForm from "@/components/AppForm";
import AppFormErrors from "@/components/AppFormErrors";
import AppFormField from "@/components/AppFormField";
import AppFormSubmit from "@/components/AppFormSubmit";

extend("max", max);
extend("required", required);
extend("regex", regex);

export default {
  name: "ModalSignInStepCProfileEdit",
  components: {
    AppFormErrors,
    AppFormField,
    AppForm,
    AppFormSubmit,
  },
  props: {
    isLoading: Boolean,
    errors: Object,
    user: User,
  },
  data: function () {
    return {
      fullName: this.user && this.user.fullName,
    };
  },
  methods: {
    submit() {
      this.$emit("submitted", {
        payload: {
          fullName: this.fullName,
        },
      });
    },
  },
};
</script>
