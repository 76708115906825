

































































import "reflect-metadata";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { User } from "@/api/structs";
import TheLogo from "@/assets/logo.svg";
import TheMenu from "@/components/TheMenu.vue";

@Component({ components: { TheMenu, TheLogo } })
export default class SignInNavbar extends Vue {
  @Prop({ required: true }) readonly isAuthenticated!: boolean;
  @Prop({ required: true }) readonly isAnonymous!: boolean;
  @Prop({ required: true }) readonly isUnknown!: boolean;
  @Prop({ required: true }) readonly user!: User | null;
  @Prop({ required: true }) readonly menuItems!: any;
  @Prop({ required: true }) readonly isOpen!: boolean;
  @Prop({ required: true }) readonly openModal!: Function;
  @Prop({ required: true }) readonly closeModal!: Function;

  private isRussian = false;

  private beforeMount() {
    // locale in ISO 639-1 format
    if (localStorage.locale) {
      this.isRussian = localStorage.locale === "ru";
    }
  }

  private toggleLanguage() {
    this.isRussian = !this.isRussian;
    localStorage.locale = this.isRussian ? "ru" : "en";
  }

  @Watch("isRussian")
  private onIsRussianChanged() {
    this.$i18n.locale = this.isRussian ? "ru" : "en";
  }
}
